import React from "react"
import styled from "styled-components"

import Heading from "../components/heading"
import Text from "../components/text"
import MenuItem from "../components/menu-item"
import LSF from "../components/letter-spacing-fix"

const Menu = styled.div`
    max-width: 674px;
    padding: 100px 15% 100px 15%;
    margin-left: 35%;
    // margin-bottom: 70vh;
    text-align: center;

    @media (max-width: 768px) {
        padding: 100px 0;
        margin: 0 15%;
    }

    @media (max-width: 425px) {
        padding: 100px 0;
        margin: 0 75px 0 20px;
    }
`

const GreyText = styled.span`
    color: #939598;
`

export default (props) => (
    <Menu id={ props.id }>
        <Heading margin="auto">Men<LSF>u</LSF></Heading>
        <Text marginBottom="40px" marginTop="40px">
            <GreyText>
                We now serve a Summer menu that is based on fruits and vegetables.
            </GreyText>
        </Text>
        <MenuItem>Tasting menu</MenuItem>
        <MenuItem><GreyText>Winter 185</GreyText></MenuItem>
        <MenuItem><GreyText>Spring 185</GreyText></MenuItem>
        <MenuItem>Summer 165</MenuItem>
        <MenuItem marginBottom="40px"><GreyText>Autumn 185</GreyText></MenuItem>
        <MenuItem>Alcoholic pairing</MenuItem>
        <MenuItem marginBottom="40px">110</MenuItem>
        <MenuItem>Non alcoholic pairing</MenuItem>
        <MenuItem marginBottom="40px">80</MenuItem>
        <Text>
            <GreyText>
                We created one set menu for all our guests. The tasting menu consists of 18 servings, starting with snacks then savory dishes and finishing with desserts and mignardises. We believe this is the best way for us to showcase what the Netherlands has to offer.
            </GreyText>
        </Text>
        <Text>
            <GreyText>
                We offer an alcoholic beverage pairing and a non-alcoholic beverage pairing. These drinks have been carefully chosen to enhance and complement the dishes served throughout the experience. We offer coffee to accompany the mignardises, this is included in the set menu.
            </GreyText>
        </Text>
        <Text>
            <a href="Tres Wijnlijst 01-2024-digital.pdf" target="_blank">Wine list 2024 (PDF)</a>
        </Text>
        <Text>
            <GreyText>
                Prices are subject to change.
            </GreyText>
        </Text>

    </Menu>
)